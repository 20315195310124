import "./App.css";
import alphard from "./assets/images/alphard.webp";
import expander from "./assets/images/xpander.webp";
import sdm from "./assets/images/sdm.webp";
import avanza from "./assets/images/avanza.webp";
import ertiga from "./assets/images/ertiga.webp";
import bri from "./assets/icons/bri.png";
import fortuner from "./assets/images/fortuner.webp";
import hiace from "./assets/images/hiace.webp";
import bca from "./assets/images/bca.webp";
import reborn from "./assets/images/reborn.webp";
import h1 from "./assets/images/h1.webp";
import pay from "./assets/images/pay.webp";
import mobilio from "./assets/images/mobilio.webp";
import pajero from "./assets/images/pajero.webp";
import twenty from "./assets/images/24-hours.webp";
import wa from "./assets/images/wa.webp";
import { BsShareFill } from "react-icons/bs";
import { toast, ToastContainer } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import Card from "./components/Card";
import CardSecondary from "./components/CardSecondary";
import { Helmet } from "react-helmet";
function App() {
  const [isScrolled, setIsScrolled] = useState(false);
  const dataCard = [
    {
      tipe: "Avanza",
      image: avanza,
      keterangan: (
        <span className="text-xs">
          Kapasitas: 7 org
          <br />
          Sewa Mobil + Sopir
          <br />
          Durasi 12 Jam
          <br />
          Harga belum termasuk BBM, Tol, Parkir dan makan sopir
        </span>
      ),
    },
    {
      tipe: "Xpander",
      image: expander,
      keterangan: (
        <span className="text-xs">
          Kapasitas: 7 org
          <br />
          Sewa Mobil + Sopir
          <br />
          Durasi 12 Jam
          <br />
          Harga belum termasuk BBM, Tol, Parkir dan makan sopir
        </span>
      ),
    },

    {
      tipe: "Mobilio",
      image: mobilio,
      keterangan: (
        <span className="text-xs">
          Kapasitas: 7 org
          <br />
          Sewa Mobil + Sopir
          <br />
          Durasi 12 Jam
          <br />
          Harga belum termasuk BBM, Tol, Parkir dan makan sopir
        </span>
      ),
    },
    {
      tipe: "Ertiga",
      image: ertiga,
      keterangan: (
        <span className="text-xs">
          Kapasitas: 7 org
          <br />
          Sewa Mobil + Sopir
          <br />
          Durasi 12 Jam
          <br />
          Harga belum termasuk BBM, Tol, Parkir dan makan sopir
        </span>
      ),
    },
    {
      tipe: "Innova",
      image: reborn,
      keterangan: (
        <span className="text-xs">
          Kapasitas: 7 org
          <br />
          Sewa Mobil + Sopir
          <br />
          Durasi 12 Jam
          <br />
          Harga belum termasuk BBM, Tol, Parkir dan makan sopir
        </span>
      ),
    },

    {
      tipe: "Pajero",
      image: pajero,
      keterangan: (
        <span className="text-xs">
          Kapasitas: 7 org
          <br />
          Sewa Mobil + Sopir
          <br />
          Durasi 12 Jam
          <br />
          Harga belum termasuk BBM, Tol, Parkir dan makan sopir
        </span>
      ),
    },
    {
      tipe: "Fortuner",
      image: fortuner,
      keterangan: (
        <span className="text-xs">
          Kapasitas: 7 org
          <br />
          Sewa Mobil + Sopir
          <br />
          Durasi 12 Jam
          <br />
          Harga belum termasuk BBM, Tol, Parkir dan makan sopir
        </span>
      ),
    },
    {
      tipe: "Hi - Ace",
      image: hiace,
      keterangan: (
        <span className="text-xs">
          Kapasitas: 16 org
          <br />
          Sewa Mobil + Sopir
          <br />
          Durasi 12 Jam
          <br />
          Harga belum termasuk BBM, Tol, Parkir dan makan sopir
        </span>
      ),
    },
    {
      tipe: "Hyundai H1",
      image: h1,
      keterangan: (
        <span className="text-xs">
          Kapasitas: 8 org
          <br />
          Sewa Mobil + Sopir
          <br />
          Durasi 12 Jam
          <br />
          Harga belum termasuk BBM, Tol, Parkir dan makan sopir
        </span>
      ),
    },

    {
      tipe: "Alphard",
      image: alphard,
      keterangan: (
        <span className="text-xs">
          Kapasitas: 7 org
          <br />
          Sewa Mobil + Sopir
          <br />
          Durasi 12 Jam
          <br />
          Harga belum termasuk BBM, Tol, Parkir dan makan sopir
        </span>
      ),
    },
  ];
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = (value) => {
    const text = value;
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    toast.success("Copied to clipboard!", { autoClose: 1000 });
  };
  const textRef = useRef(null);
  const [salin, setSalin] = useState(false);

  const dataSecondary = [
    // {
    //   image: car,
    //   title: "Armada Lengkap",
    //   description:
    //     "Varian jenis dan tipe mobil paling lengkap terdiri dari mobil dengan transmisi manual dan matic.",
    // },
    {
      image: sdm,
      title: "Sumber Daya Manusia",
      description: (
        <p>
          Kami hanya menggunakan SDM sopir dengan jam terbang tinggi, ramah dan
          memiliki attitude baik.
        </p>
      ),
    },
    {
      image: twenty,
      title: "Buka 24 Jam",
      description: (
        <>
          <p className="">
            Kapanpun anda butuh layanan rental mobil, CS kami dapat dihubungi
            via telp online 24 jam.
          </p>
          <br />
          <a href="tel:6287814003600" className="text-md font-semibold">
            0878 1400 3600
          </a>
          <br />
          <br />
          <a href="tel:6287814003599" className="text-md font-semibold">
            0878 1400 3599
          </a>
        </>
      ),
    },
    // {
    //   image: lowPrice,
    //   title: "Harga Sewa Murah",
    //   description:
    //     "Harga sewa murah dan kompetetif dengan tetap mengedepankan mutu dan kualitas layanan maksimal.",
    // },
    // {
    //   image: highQuality,
    //   title: "Layanan Berkualitas",
    //   description:
    //     "Semua unit mobil adalah armada terbaru dengan didukung oleh, driver profesional dan dapat diandalkan.",
    // },
    {
      image: pay,
      title: "Jaminan Keamanan Uang Anda",
      description: (
        <>
          <p className="">
            Segala pembayaran dilakukan dengan cara transfer ke rekening bank
            kami.
          </p>
          <p className="text-sm font-bold">PT. RENTAL MOBIL MEKAR</p>
          <br />
          <span className="">
            <span className="text-xs">Tekan nomor rekening untuk menyalin</span>
          </span>
          {/* baru */}
          <div className="flex flex-col mt-5">
            <div className="flex gap-3">
              <div className="w-20">
                <img
                  alt="bri"
                  src={bri}
                  className="inline-block h-[60px]"
                  loading="lazy"
                  lazy="true"
                ></img>
              </div>
              <div className="flex-1 flex flex-col items-start ">
                <input
                  type="text"
                  value="038601002039566"
                  ref={textRef}
                  style={{ position: "absolute", left: "-9999px" }}
                  className=""
                />
                <button
                  onClick={() => handleCopy("038601002039566")}
                  className="text-lg "
                >
                  038601002039566
                </button>
                <span>a/n</span>
                <div className="">PT. Rental Mobil Mekar</div>
              </div>
            </div>
            <div className="flex gap-3">
              <div className="w-20">
                <img
                  alt="bri"
                  src={bca}
                  className="inline-block h-[60px]"
                  loading="lazy"
                  lazy="true"
                ></img>
              </div>
              <div className="flex-1 flex flex-col items-start pt-3">
                <input
                  type="text"
                  value="0920107761"
                  ref={textRef}
                  style={{ position: "absolute", left: "-9999px" }}
                  className=""
                />
                <button
                  onClick={() => handleCopy("0920107761")}
                  className="text-lg "
                >
                  0920107761
                </button>
                <span>a/n</span>
                <span>Rio Sandy Setyono</span>
              </div>
            </div>
          </div>
          {/* lama */}
          {/* <div className="flex flex-col mt-4">
            <div className="flex flex-row gap-5 justify-between">
              <img
                alt="bri"
                src={bri}
                className="inline-block h-[60px]"
                loading="lazy"
                lazy="true"
              ></img>
              <div className="flex flex-col items-center gap-2 flex-1 justify-start">
                <input
                  type="text"
                  value="038601002039566"
                  ref={textRef}
                  style={{ position: "absolute", left: "-9999px" }}
                  className=""
                />
                <button onClick={handleCopy} className="text-lg ">
                  038601002039566
                </button>
                <div className="">a/n PT. Rental Mobil Mekar</div>
              </div>
            </div>
            <div className="flex flex-row gap-5 justify-between">
              <img
                alt="bca"
                src={bca}
                className="inline-block h-[60px]"
                loading="lazy"
                lazy="true"
              ></img>
              <div className="flex flex-col items-center gap-2 flex-1 justify-start">
                <input
                  type="text"
                  value="0920107761"
                  ref={textRef}
                  style={{ position: "absolute", left: "-9999px" }}
                />
                <button onClick={handleCopy} className="text-lg">
                  0920107761
                </button>
                <div className="mr-auto">a/n Rio Sandy Setyono</div>
              </div>
            </div>
          </div> */}
        </>
      ),
    },
  ];
  const handleShareClick = () => {
    navigator.share({
      title: "RENTAL MOBIL JAKARTA",
      text: "Sewa mobil murah di Jakarta dengan mobil berkualitas dan pelayanan terbaik dari Rental Mobil Jakarta",
      url: "https://rentalmobiljakarta.online",
    });
  };
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div
      className="App font-gotham bg-gradient-to-r from-red-600 to-highlight"
      data-theme="bumblebee"
    >
      <Helmet>
        <title>RENTAL MOBIL JAKARTA</title>
        <meta
          name="description"
          content="Sewa mobil murah di Jakarta dengan mobil berkualitas dan pelayanan terbaik dari Rental Mobil Jakarta"
        />
        <meta
          name="keywords"
          content="rental mobil Jakarta, sewa mobil murah, rental mobil berkualitas"
        />
        <meta
          property="og:title"
          content="Rental Mobil Murah di Jakarta - Rental Mobil Jakarta"
        />
        <meta
          property="og:image"
          content="https://rentalmobiljakarta.online/static/media/logo.2e73b047ad7619c946de.png"
        />
        <meta
          property="og:description"
          content="Sewa mobil murah di Jakarta dengan pilihan mobil berkualitas dan pelayanan terbaik dari Rental Mobil Jakarta"
        />
        <link rel="canonical" href="https://rentalmobiljakarta.online" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div
        onClick={() =>
          window.open(
            "https://wa.me/6287814003600?text=Halo,%20Saya%20ingin%20menyewa%20mobil."
          )
        }
        className="cursor-pointer fixed bottom-20 right-0 p-2 rounded-l-2xl text-green-600 z-10"
      >
        <img
          alt="whatsapp"
          className="h-[45px] w-[45px]"
          src={wa}
          loading="lazy"
          lazy="true"
        ></img>
      </div>
      <header>
        <nav className="navbar fixed top-0 w-full flex flex-col  bg-gradient-to-r from-red-600 to-highlight rounded-b-3xl z-10 py-3 shadow-2xl shadow-yellow-500">
          <h1 className="text-2xl font-black lg:text-4xl 2xl:text-6xl mr-auto text-white text-shadow">
            RENTAL MOBIL JAKARTA
          </h1>

          <p className="text-white text-sm mr-auto">PT. RENTAL MOBIL MEKAR</p>
          <div
            onClick={handleShareClick}
            className="flex flex-col gap-1 ml-auto pr-3 cursor-pointer"
          >
            <BsShareFill className="text-white mt-1 " />
            <button className="text-xs text-white">Bagikan</button>
          </div>
        </nav>
      </header>

      <main>
        <div className=" flex items-center justify-center bg-main-mobile pt-36">
          <div className=" text-center w-[95vw]">
            <h2 className="text-white mb-8 text-left 2xl:text-2xl text-xs">
              Nikmati perjalanan anda bersama kami,
              <br />
              RENTAL MOBIL JAKARTA <br />
              Kami Menyediakan fasilitas sewa mobil untuk perjalanan Dinas,
              Wisata, Liburan tujuan dalam kota dan luar kota. <br />
              Beragam pilihan armada yang kami sediakan dan pengemudi yang
              handal berpengalaman untuk mengantarkan perjalanan anda.
              <br /> Silahkan pilih Armada yang anda inginkan
            </h2>
            <div className="">
              <div className="text-left text-white text-xl 2xl:text-6xl font-bold my-3 lg:text-4xl">
                <h2>Pilihan Armada</h2>
              </div>
              <div className="flex flex-wrap lg:grid lg:grid-cols-3 gap-4 2xl:grid-cols-5 justify-center">
                {dataCard.map((data, index) => (
                  <Card
                    image={data.image}
                    tipe={data.tipe}
                    keterangan={data.keterangan}
                  ></Card>
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>
      <main>
        <div className="bg-cover py-16">
          <div className="py-10 px-1">
            <div className="flex flex-wrap lg:grid lg:grid-cols-3 gap-10 justify-center px-5">
              {dataSecondary.map((data, index) => (
                <CardSecondary
                  title={data.title}
                  description={data.description}
                  image={data.image}
                />
              ))}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
