import React from "react";

const CardSecondary = (props) => {
  return (
    <div className="card lg:card-side shadow-2xl bg-gradient-to-r from-red-600 to-highlight text-white ">
      <figure className="p-2 max-w-sm lg:w-[400px] lg:ml-4">
        <img
          src={props.image}
          alt=""
          className="h-[75px] w-[75px] "
          loading="lazy"
          lazy="true"
        />
      </figure>
      <div className="card-body">
        <h2 className="card-title font-bold text-2xl">{props.title}</h2>
        <p>{props.description}</p>
      </div>
    </div>
  );
};

export default CardSecondary;
