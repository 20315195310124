import React from "react";
import { AiOutlineWhatsApp } from "react-icons/ai";

const Card = (props) => {
  return (
    <div className="cardCar mb-10">
      <div className="flex border-highlight">
        <div className="p-1 w-3/5 flex flex-wrap justify-start">
          <img
            className="inline-block h-[150px]"
            src={props.image}
            alt="Shoes"
          />
          <h2 className="card-title text-white">{props.tipe}</h2>
        </div>
        <div className="w-2/5 text-white text-left">
          <p className="text-sm">{props.keterangan}</p>
        </div>
      </div>
      <div
        onClick={() =>
          window.open(
            `https://wa.me/6287814003600?text=Halo,%20Mohon%20informasi%20sewa%20mobil%20${props.tipe}.`
          )
        }
        className="cursor-pointer hover:-translate-y-3 rounded-full inline-block lg:py-4 lg:px-16 py-1 px-10 my-2 mx-auto bg-gradient-to-r from-green-400 to-green-600 text-white text-sm 2xl:text-2xl"
      >
        <AiOutlineWhatsApp className="w-[30px] h-[30px] mr-2 inline" />

        <span>
          <p className="inline text-lg">
            Pesan<p className="text-transparent inline">_</p>Mobil
          </p>
        </span>
      </div>
    </div>
  );
};

export default Card;
